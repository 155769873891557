<template>
    <div>
        <h5 class="border-bottom pb-2 text-primary">User devices</h5>
        <paginated-result-loader :endpoint="apiEndpoint" cell-type="user_devices"
                                 :filters="request"></paginated-result-loader>
    </div>
</template>

<script>


    import PaginatedResultLoader from "../PaginatedResultLoader";
    export default {
        name: "UserDevices",
        components: {PaginatedResultLoader},
        props: {
            userId: null,
        },
        data: function () {
            return {
                request : [],
                apiEndpoint: 'users/'+this.userId+"/devices"
            }
        },
        created() {

        },
        methods: {

        }
    }
</script>

<style scoped>

</style>
